<template>
	<div class="v-select py-20" :class="{ disabled: disabled, small: small }">
		<label v-html="selectedOption[optionText]"></label>

		<select :value="value" ref="select" @change="change" :disabled="disabled">
			<option v-for="option in options" :value="option[optionValue]" :key="'optionValue-' + option[optionValue]">{{ option[optionText] }}</option>
		</select>
	</div>
</template>

<script>
export default {
	name: 'VSelect',

	props: ['value', 'options', 'small', 'optionText', 'optionValue', 'disabled'],

	data() {
		return {
			selectedValue: null,
		};
	},

	computed: {
		selectedOption() {
			for (let option of this.options) {
				if (option[this.optionValue] == this.selectedValue) {
					return option;
				}
			}

			return {};
		},
	},

	watch: {
		value: {
			immediate: true,

			handler(value) {
				if (!value || !this.optionValue) {
					return;
				}

				this.selectedValue = value[this.optionValue];
			},
		},

		// options: function () {
		// 	this.$refs.selectedIndex = 6;
		// },
	},

	methods: {
		change(e) {
			this.selectedValue = e.target.value;

			this.$emit('input', this.selectedOption);
		},
	},
};
</script>
